import { IUser, Languages } from '@/store/users/users/interfaces';

export const EmptyItem: IUser = {
  id: 0,
  name: '',
  real_name: '',
  comment: '',
  groups: [],
  primary_group: 0,
  active: true,
  password: '',
  confirm_password: '',
  language: Languages.en
};

import { IFormSchema } from '@/definitions/base.form.schema';

const schema: IFormSchema = {
  fields: [
    {
      prop: '',
      i18nLabel: '',
      component: {
        name: 'user-faces'
      }
    },
    {
      prop: 'id',
      i18nLabel: 'id',
      component: {
        name: 'text-field'
      }
    },
    {
      prop: 'real_name',
      i18nLabel: 'name',
      component: {
        name: 'input'
      },
      validators: [
        {
          required: true,
          props: {
            i18nMessage: 'error.required.field',
            maxLength: 80
          },
          trigger: 'change'
        }
      ]
    },
    {
      prop: 'name',
      i18nLabel: 'login',
      component: {
        name: 'input'
      },
      validators: [
        {
          required: true,
          props: {
            i18nMessage: 'error.required.login',
            maxLength: 80
          },
          trigger: 'change'
        },
        {
          name: 'string',
          props: {
            i18nMessage: 'error.string.should_be_ascii'
          },
          trigger: 'change'
        }
      ]
    },
    {
      prop: 'password',
      component: {
        name: 'user-passwords'
      }
    },
    {
      prop: 'primary_group',
      i18nLabel: '',
      component: {
        name: 'user-primary-group'
      }
    },
    {
      prop: 'comment',
      i18nLabel: 'comment',
      component: {
        name: 'input',
        type: 'textarea'
      }
    },
    {
      prop: 'active',
      component: {
        name: 'user-active-checkbox'
      }
    }
  ]
};

export default schema;



















































import _ from '@/apps/common/lodash';
import BaseForm from '@/components/common/base-form/index.vue';
import ConfirmButton from '@/components/confirm/confirm-button.vue';
import PageLayout from '@/components/page/layout.vue';
import { createUserModule } from '@/store/users/users';
import { IUser, Languages } from '@/store/users/users/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import formSchema from './schema';

@Component({
  name: 'user-form',
  components: {
    PageLayout,
    BaseForm,
    ConfirmButton
  }
})
export default class UserForm extends Vue {
  @Ref()
  private readonly baseForm!: BaseForm;
  private readonly module = createUserModule(this.$store);
  certificate = null;

  get userFormTitle() {
    return this.isCreateMode ? this.$tf(['common.create', 'common.user,, 1']) : this.$tf(['common.edit', 'common.user,, 1']);
  }

  get submitButtonText() {
    return this.isCreateMode ? this.$tf(['common.create']) : this.$tf(['common.update']);
  }

  get state() {
    return this.module.state;
  }

  get schema() {
    return formSchema;
  }

  get userId() {
    return this.$route.params.id;
  }

  get isCreateMode() {
    return !this.userId;
  }

  get isCurrentUser() {
    return this.userId === 'me';
  }

  get isDisabledSubmit() {
    return (
      !this.state.item?.primary_group || (this.isCreateMode ? this.$hasNoPermission('ffsecurity.add_user') : this.$hasNoPermission('ffsecurity.change_user'))
    );
  }

  created() {
    this.load();
  }

  load() {
    const { item, id } = this.$route.params;

    if (item) {
      this.state.setItemsState(item as any as IUser);
    } else if (id) {
      this.state.get(id);
    }
    this.loadCertificate();
  }

  loadCertificate() {
    if (!this.$store.state.config.plugins.cproauth) return;
    const id = this.$route.params.id;
    this.$store
      .dispatch('getUserCertificate', { id })
      .then((v) => {
        this.certificate = v;
      })
      .catch((e) => {
        this.certificate = null;
      });
  }

  cancelHandler() {
    this.$router.back();
  }

  deleteHandler(e) {
    this.$store
      .dispatch(this.$store.state.users.Action.Delete, this.state.item)
      .then((v) => {
        this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
        this.$router.push({ path: '/users/filter/ordering=id' });
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      });
  }

  deleteCertificateHandler() {
    this.$store.state.cryptopro.loading = true;
    this.$store
      .dispatch('deleteUserCertificate', this.state.item)
      .then((v) => {
        this.$notify({ type: 'success', message: this.$tf('action | success') });
        this.loadCertificate();
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.$store.state.cryptopro.loading = false;
      });
  }

  addCertificateHandler() {
    if (!this.$cryptopro) {
      this.$notify({ type: 'error', message: this.$tf('error.crypto_pro.plugin_install') });
      return;
    }

    if (!this.$cryptopro.hasPlugin) {
      this.$notify({ type: 'error', message: this.$tf('error.crypto_pro.plugin_install') });
      return;
    }

    this.$store
      .dispatch('getCertificates')
      .then((v) => {
        this.$store.state.dialog.cryptopro.enabled = true;
        this.$store.state.dialog.cryptopro.action = this.addSignCryptoPro.bind(this);
      })
      .catch((e) => {
        const errorItem = e?.message ?? e;
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: errorItem } }) });
      })
      .finally(() => {
        this.$store.state.cryptopro.loading = false;
      });
  }

  addSignCryptoPro() {
    this.$store.state.dialog.cryptopro.enabled = false;
    this.$store.state.cryptopro.loading = true;

    this.$store
      .dispatch(this.isCurrentUser ? 'setCurrentUserCertificate' : 'setUserCertificate', this.state.item)
      .then((v) => {
        this.$notify({ type: 'success', message: this.$tf('action | success') });
        this.loadCertificate();
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.$store.state.cryptopro.loading = false;
      });
  }

  async submit() {
    const { item } = this.state;
    const isFormValid = (await this.baseForm?.validate()) && item?.passwordsValid;
    let fields = ['id', 'name', 'real_name', 'groups', 'active', 'comment', 'primary_group'];
    let instance;
    let result;

    if (item?.password) fields.push('password', 'confirm_password');
    instance = _.pick(item, fields);

    if (isFormValid) {
      try {
        if (instance?.id) {
          await this.state.update({ ...instance });
        } else {
          let locale = window.localStorage.locale;
          if (locale in Languages && this.state.item) {
            this.state.item.language = Languages[locale];
          }
          result = await this.state.create();
          await this.$router.replace({ path: `/users/${this.isCurrentUser ? 'me' : result.id}/` });
        }
        this.$notify({ type: 'success', message: this.$tf('action | success') });
      } catch (e) {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      }
    } else {
      this.$notify({
        duration: 0,
        message: this.$createElement('message-box', { props: { e: this.$tf('error.form.validation') } })
      });
    }
  }

  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.load();
    next();
  }

  beforeDestroy() {
    this.module.unreg();
  }
}

import _ from '@/apps/common/lodash';
import { BaseItemStateModule } from '@/definitions/base.item.state.module';
import { configureDynamicStoreModuleFactory } from '@/store/common';
import { IUser } from '@/store/users/users/interfaces';
import { EmptyItem } from '@/store/users/users/items';
import { Module, RegisterOptions } from 'vuex-class-modules';
import store from '../../index';

@Module({ generateMutationSetters: true })
export class UserModule extends BaseItemStateModule<IUser> {
  name = 'users';
  routeName = 'users';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyItem);
    this.setEmptyItemsState();
  }

  dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

export const createUserModule = configureDynamicStoreModuleFactory('user', (store, name) => {
  return new UserModule({ store, name });
});
